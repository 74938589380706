import {contextColors, themeColors} from '../@theme/theme-colors';
import stc from 'string-to-color';

export function getStateColor(state: number, stops: {state: number; color: string}[] = [{
    state: 0,
    color: contextColors.danger,
}, {state: 0.5, color: contextColors.warning}, {state: 1, color: contextColors.success}]) {
    stops = stops.sort((a, b) => a.state - b.state);
    state = Math.max(Math.min(state, stops[stops.length - 1].state), stops[0].state);
    const maxI = stops.findIndex(s => s.state >= state);
    const minStop = stops[maxI - 1] || stops[maxI], maxStop = stops[maxI];
    if (minStop.color == maxStop.color) return minStop.color;
    const minRgb = hexToRgb(minStop.color), maxRgb = hexToRgb(maxStop.color);
    if (!minRgb || !maxRgb) return null;
    const ratio = Math.min(Math.max((state - minStop.state) / (maxStop.state - minStop.state), 0), 1);
    const getColorKey = k => Math.round(minRgb[k] + (maxRgb[k] - minRgb[k]) * ratio);
    return rgbToHex(getColorKey('r'), getColorKey('g'), getColorKey('b'));
}

export function hexToRgb(hex: string): {r: number; g: number; b: number} {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ?
        {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        } :
        null;
}

export function rgba(hex: string, alpha: number): string {
    const rgb = hexToRgb(hex);
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
}

export function rgbToHex(r: number, g: number, b: number): string {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
}

export function getBrightness(hex: string): number {
    const rgb = hexToRgb(hex);
    return (rgb.r + rgb.g + rgb.b) / 255 / 3;
}

export function changeBrightness(hex: string, ratio: number): string {
    const rgb = hexToRgb(hex);
    ['r', 'g', 'b'].forEach(c => rgb[c] = Math.round(Math.min(rgb[c] + (255 - rgb[c]) * ratio, 255)));
    return rgbToHex(rgb.r, rgb.g, rgb.b);
}

export function setBrightness(hex: string, brightness: number): string {
    const rgb = hexToRgb(hex);
    const currBrightness = getBrightness(hex);
    const diff = brightness - currBrightness;
    ['r', 'g', 'b'].forEach(c => rgb[c] = Math.round(Math.min(
        diff < 0 ?
            rgb[c] * (1 - (currBrightness - brightness) / currBrightness) :
            rgb[c] + (255 - rgb[c]) * (brightness - currBrightness) / (1 - currBrightness),
        255)));
    return rgbToHex(rgb.r, rgb.g, rgb.b);
}

export function addColors(hex1: string, hex2: string, ratio = 1): string {
    const rgb1 = hexToRgb(hex1), rgb2 = hexToRgb(hex2);
    const rgb: any = {};
    ['r', 'g', 'b'].forEach(c => rgb[c] = Math.round(Math.max(Math.min(rgb1[c] + rgb2[c] * ratio, 255), 0)));
    return rgbToHex(rgb.r, rgb.g, rgb.b);
}

export function limitBrightness(hex: string, brightness: number, mode: 'max' | 'min' = 'max') {
    brightness = Math.min(Math.max(brightness, 0), 1);
    const currBrightness = getBrightness(hex);
    return (mode == 'max' && currBrightness > brightness) || (mode == 'min' && currBrightness < brightness) ? setBrightness(hex, brightness) : hex;
}

export function maxBrightness(hex: string, brightness: number) {
    return limitBrightness(hex, brightness, 'max');
}

export function minBrightness(hex: string, brightness: number) {
    return limitBrightness(hex, brightness, 'min');
}

export function stringToColor(text: string): string {
    return stc(text);
}

export function getColorWithOpacity(ratio: number, baseColor = themeColors[0]): {bg: string; text: string} {
    ratio = Math.max(0, Math.min(1, ratio));
    let alphaHex = Math.round(ratio * 255).toString(16);
    if (alphaHex.length < 2) alphaHex = `0${alphaHex}`;
    return {
        bg: ratio > 0 ? baseColor + alphaHex : '#fff',
        text: !ratio || ratio < .25 ? baseColor : '#fff',
    };
}

export function getColorClass(x): string {
    if (x.is_critical) return 'danger';
    if (x.is_abnormal) return 'warning';
    if (x.is_inconclusive || x.is_no_result) return 'secondary';
    return '';
}

export function getPatientInformationStatusClass(x): string {
    if (x.status === 'REVOKED' || x.status === 'REJECTED' || x.status === 'DISCARDED' || x.status === 'DISCONTINUED' || x.status === 'DECLINED' || x.status === 'CANCELLED') return 'danger';
    if (x.status === 'REQUESTED') return 'warning';
    if (x.status === 'AUTHORIZED' || x.status === 'IMPORTED') return 'success';
    return '';
}

export function getPatientInformationStatusColor(x): string {
    if (x.status === 'REVOKED' || x.status === 'REJECTED' || x.status === 'DISCARDED' || x.status === 'DISCONTINUED' || x.status === 'DECLINED' || x.status === 'CANCELLED') return contextColors.danger;
    if (x.status === 'REQUESTED') return contextColors.warning;
    if (x.status === 'AUTHORIZED' || x.status === 'IMPORTED') return contextColors.success;
    return contextColors.primary;
}
